import ua from "./ua.js";
import http from "./http.js";
import openai from "./openai.js";
import layer from "./layer.js";
import date from "./date.js";
import clone from "./clone.js";
import cookies from "./cookies.js";
import other from "./other.js";
import core from "./dm-core.js";
import anime from "./anime.js";
import lodash from "lodash";
import config from "@/dm_config.js";
let dm = {
  ...ua,
  ...cookies,
  ...other,
  ...clone,
  ...core,
  ...anime,
  http,
  openai,
  layer,
  date,
  ...config,
  _: lodash(),
};
window.dm = dm;
