<template>
  <div v-if="loading"
       class="loading">
    <a-spin size="large" />
  </div>
</template>

<script>
import { ref, toRefs, toRef } from 'vue'
export default {
  name: 'DmLoading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let { loading } = toRefs(props)
    return { loading }
  },
}
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
