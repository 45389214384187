import axios from 'axios'
//创建axios,赋给变量service
const service = axios.create({
  // baseURL: 'https://cnodejs.org/api/v1',
  baseURL: '/esg-api',
  timeout: 10000, //超时
  headers: { 'Content-Type': 'application/json' }
})
import layer from './layer'
const removerCookies = (callback) => {
  // 方法一：使用 document.cookie 属性和过期时间来清除所有cookie

  // 获取当前所有的cookie
  var cookies = document.cookie.split(";")

  // 将过期时间设置为过去的时间，以清除cookie
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf("=")
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
  }
  if (callback && typeof callback === "function") {
    callback()
  }
}
const showStatus = status => {
  let message = ''
  switch (status) {
    case 301:
      message = '登录失效，请重新登录！'
      break
    case 400:
      message = '请求错误(400)'
      break
    case 401:
      message = '未授权，请重新登录(401)'
      break
    case 402:
      message = '拒绝访问(402)'
      break
    case 404:
      message = '请求出错(404)'
      break
    case 408:
      message = '请求超时(408)'
      break
    case 500:
      message = '服务器错误(500)'
      break
    case 501:
      message = '服务未实现(501)'
      break
    case 502:
      message = '网络错误(502)'
      break
    case 503:
      message = '服务不可用(503)'
      break
    case 504:
      message = '网络超时(504)'
      break
    case 505:
      message = 'HTTP版本不受支持(505)'
      break
    default:
      message = `连接出错(${status})!`
  }
  return `${message}，请检查网络或联系管理员！`
}
// 请求拦截器
service.interceptors.request.use(
  config => {
    if (dm.getCookie('token')) {
      config.headers['token'] = dm.getCookie('token')
    }
    return config
  },
  err => {
    err.message = '服务器异常，请联系管理员！'
    // 错误抛到业务代码
    return Promise.reject(err)
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    const status = response.status
    let msg = ''

    if (status < 200 || (status >= 300 && status != 401 && status != 500)) {
      // 处理http错误，抛到业务代码
      msg = showStatus(status)
      if (typeof response.data === 'string') {
        response.data = { msg }
      } else {
        response.data.msg = msg
      }
      return response
    } else if (status == 200) {

      if (response.data.code == 301) {
        msg = showStatus(301)
        layer.alert({
          title: "提示",
          content: "当前未登录或者登录过期，请重新登录！",
          type: 'error'
        }).then(() => {
          location.href = "/home/index"
          removerCookies()
        })

      }
      if (response.data.code == 302) {
        layer.alert({
          title: "提示",
          content: "无权限访问！",
          type: 'error'
        }).then(() => {
          location.href = "/home/index"
        })

      }
      return response
    } else if (status == 500) {
      msg = showStatus(status)
      response.data = { msg: msg }
      return response
    }
  },
  err => {
    err.message = '请求超时或服务器异常，请检查网络或联系管理员！'
    return Promise.reject(err)
  }
)

function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'get',
      params: params
    })
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
function post(url, data = {}, headers) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'post',
      data: data,
      headers
    })
      .then(res => {
        if (res.data) {
          resolve(res.data)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
function put(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: 'get',
      data: params
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default {
  get,
  post,
  put
}
