import {
  KeepAlive
} from 'vue'
import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

let routes = [{
  path: '/',
  redirect: '/home'
},
{
  path: '/home',
  name: 'home',
  component: () => import( /* home: "home" */ '@/views/home.vue'), //
  meta: {
    title: '首页',
    // keepAlive: true
  },
  redirect: '/home/index',
  children: [{
    path: 'index',
    name: 'index',
    component: () =>
      import( /* webpackChunkName: "home-index" */ '@/views/home/index.vue'), //
    meta: {
      title: '首页',
      keepAlive: true
    }
  },
  {
    path: 'productServer',
    name: 'productServer',
    component: () =>
      import(
        /* webpackChunkName: "home-productServer" */
        '@/views/home/productServer.vue'
      ), //
    meta: {
      title: '产品服务'
    }
  },
  {
    path: 'industyNews',
    name: 'industyNews',
    component: () =>
      import(
        /* webpackChunkName: "home-industyNews" */
        '@/views/home/industyNews.vue'
      ), //
    meta: {
      title: '行业资讯'
    }
  },
  // {
  //   path: "teamsDesc",
  //   name: "teamsDesc",
  //   component: () => import(/* webpackChunkName: "home-teamsDesc" */ "@/views/home/teamsDesc.vue"), //
  //   meta: {
  //     title: "团队介绍",
  //   },
  // },
  {
    path: 'concatUp',
    name: 'concatUp',
    component: () =>
      import(
        /* webpackChunkName: "home-concatUp" */
        '@/views/home/concatUp.vue'
      ), //
    meta: {
      title: '联系我们'
    }
  },
  {
    path: 'tzjz',
    name: 'tzjz',
    component: () =>
      import(
        /* webpackChunkName: "home-concatUp" */
        '@/views/home/tzjz.vue'
      ), //
    meta: {
      title: '投资价值'
    }
  },
  {
    path: 'business',
    name: 'business',
    component: () =>
      import( /* home: "home-business" */ '@/views/home/business/index.vue'), //
    meta: {
      title: '企业详情'
    }
  },
  {
    path: 'newsDetail',
    name: 'newsDetail',
    component: () =>
      import( /* home: "home-newsDetail" */ '@/views/home/newsDetail.vue'), //
    meta: {
      title: '文章详情'
    }
  },
  {
    path: 'articleAdmin',
    name: 'articleAdmin',
    component: () =>
      import(
        /* home: "home-newsDetail" */
        '@/views/home/adminModule/index.vue'
      ),
    meta: {
      title: '文章管理',
      keepAlive: true
    }
  },
  {
    path: 'articleEdit',
    name: 'articleEdit',
    component: () =>
      import(
        /* home: "home-newsDetail" */
        '@/views/home/adminModule/articleEdit.vue'
      ), //
    meta: {
      title: '文章编辑',
    }
  },
  {
    path: 'itemEdit',
    name: 'itemEdit',
    component: () =>
      import(
        /* home: "home-newsDetail" */
        '@/views/home/adminModule/itemEdit.vue'
      ), //
    meta: {
      title: '项目管理'
    }
  }
  ]
},
// {
//   path: "/login",
//   name: "login",
//   component: () => import(/* home: "home" */ "@/views/login.vue"), //
//   meta: {
//     title: "登录",
//   },
// },

{
  path: '/art',
  name: 'art',
  component: () => import( /* home: "home" */ '@/views/art.vue'), //
  meta: {
    title: '文章详情'
  }
}
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // 如果 `savedPosition` 存在，则保留到该位置的滚动
    if (savedPosition) {
      return savedPosition;
    } else {
      // 如果不存在，则返回顶部
      return { top: 0 };
    }
  },
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes
})

router.afterEach((to, from, next) => {
  console.log("to", to);
  //  document.title = to.meta.title || "挚冉云"
  // if (!to.meta.keepAlive) {
  //   window.scrollTo(0, 0)
  //   chrome
  //   document.body.scrollTop = 0
  //   firefox
  //   document.documentElement.scrollTop = 0
  //   // safari
  //   window.pageYOffset = 0

  // }
  // next()
})
export default router