import { ElMessage, ElMessageBox } from "element-plus";
import "element-plus/theme-chalk/index.css";
import { Notify, Dialog, Loading } from "vant";
import "vant/es/notify/style";
import "vant/es/dialog/style";
import { IsPC } from "./ua.js";
import { h, render, createApp } from "vue";
let rroyNode = null;
// let app = createApp(App);
//pc端轻轻提示
const MessagePc = (
  options = {
    message: "npm搜索dm-core", //
    type: "success", //
    customClass: "dm-message", //
  }
) => {
  /*
   *@描述:options配置项，同element-plus mesage消息提示，链接:https://element-plus.gitee.io/zh-CN/component/message.html#message-%E9%85%8D%E7%BD%AE%E9%A1%B9
   *@作者: 肖华东
   *@日期: 2023-11-13 11:33:47
   *@message: 消息文字
   *@type: 消息类型 可选值:'success' | 'warning' | 'info' | 'error'
   *@icon: 自定义图标，该属性会覆盖 type 的图标。
   *@dangerouslyUseHTMLString: 是否将 message 属性作为 HTML 片段处理
   *@customClass: 自定义类名
   *@duration: 显示时间，单位为毫秒。 设为 0 则不会自动关闭
   *@showClose: 是否显示关闭按钮
   *@center: 文字是否居中
   *@onClose: 关闭时的回调函数, 参数为被关闭的 message 实例
   *@offset:  距离窗口顶部的偏移量
   *@appendTo:  设置 message 的根元素，默认为 document.body
   *@grouping: 合并内容相同的消息，不支持 VNode 类型的消息
   *@repeatNum: 重复次数，类似于 Badge 。当和 grouping 属性一起使用时作为初始数量使用
   */
  ElMessage(options);
};
//移动端消息通知
const MessageMobile = (
  options = {
    message: "npm搜索dm-core", //
    type: "success", //
    className: "dm-message", //
  }
) => {
  /*
   *@描述:options配置项，同vant消息提示，链接:https://vant-contrib.gitee.io/vant/v3/#/zh-CN/notify
   *@作者: 肖华东
   *@日期: 2023-11-13 11:33:47
   *@message: 展示文案，支持通过\n换行
   *@type: 消息类型 可选值: primary success warning
   *@duration: 展示时长(ms)，值为 0 时，notify 不会消失
   *@position : 弹出位置，可选值为 top bottom
   *@color: 字体颜色
   *@background: 背景颜色
   *@className:  自定义类名
   *@lockScroll :  是否锁定背景滚动
   *@onClick: 点击时的回调函数
   *@onOpened: 完全展示后的回调函数
   *@onClose: 关闭时的回调函数
   */
  Notify(options);
};

//pc端确认框
const AlertPc = (options = {}) => {
  Object.assign({
    // 是否显示关闭按钮
    showClose: true,

    // 提示内容-支持vnode
    content: "内容",
    // 关闭按钮文字
    closeText: "关闭",
    // 确认按钮文字
    confirmButtonText: "确定",
    // 取消按钮文字
    cancelButtonText: "取消",
    // 提示类型图标
    icon: "el-icon-success",
    // 提示类型
    type: "success",
    // 提示标题
    title: "标题",
    // 是否显示底部按钮
    bottom: true,
    // 自定义类名
    customClass: "dm-alert custom-message-box",
    draggable: false,
    // 是否全屏显示
    fullscreen: false,
    // 是否模态
    modal: true,
    // 模态的类名
    modalClass: "dm-modal",
    // 是否将弹窗添加到body中
    appendToBody: false,
    // 是否锁定滚动
    lockScroll: true,
    // 打开弹窗的延迟时间
    openDelay: 0,
    // 关闭弹窗的延迟时间
    closeDelay: 0,
    // 点击弹窗外部是否关闭弹窗
    closeOnClickModal: true,
    // 按下Esc键是否关闭弹窗
    closeOnPressEscape: true,
    // 关闭弹窗时是否销毁组件
    destroyOnClose: true,
    // 关闭弹窗时的图标
    closeIcon: "",
    // 弹窗的z-index
    zIndex: 0,
    // 弹窗头部是否添加aria-level属性
    headerAriaLevel: "",
    // 是否让弹窗居中
    center: false,
    // 是否让弹窗居中显示，如果设置为true，则忽略center属性
    alignCenter: false,
    // 关闭弹窗时的回调函数
    beforeClose: () => { },
    close: () => { },
    // 弹窗距离顶部的距离
    top: "15vh",
  }, options);

  return ElMessageBox.alert(options.content, options);
};
const ConfirmPc = (options = {}) => {
  Object.assign({
    // 是否显示关闭按钮
    showClose: true,
    // 提示内容-支持vnode
    content: "内容",
    // 关闭按钮文字
    closeText: "关闭",
    // 确认按钮文字
    confirmButtonText: "确定",
    // 取消按钮文字
    cancelButtonText: "取消",
    // 提示类型图标
    icon: "el-icon-success",
    // 提示类型
    // type: "warning",
    // 提示标题
    title: "标题",
    // 是否显示底部按钮
    bottom: true,
    // 自定义类名
    customClass: "dm-alert custom-message-box",
    draggable: false,
    // 是否全屏显示
    fullscreen: false,
    // 是否模态
    modal: true,
    // 模态的类名
    modalClass: "dm-modal",
    // 是否将弹窗添加到body中
    appendToBody: false,
    // 是否锁定滚动
    lockScroll: true,
    // 打开弹窗的延迟时间
    openDelay: 0,
    // 关闭弹窗的延迟时间
    closeDelay: 0,
    // 点击弹窗外部是否关闭弹窗
    closeOnClickModal: true,
    // 按下Esc键是否关闭弹窗
    closeOnPressEscape: true,
    // 关闭弹窗时是否销毁组件
    destroyOnClose: true,
    // 关闭弹窗时的图标
    closeIcon: "",
    // 弹窗的z-index
    zIndex: 0,
    // 弹窗头部是否添加aria-level属性
    headerAriaLevel: "",
    // 是否让弹窗居中
    center: false,
    // 是否让弹窗居中显示，如果设置为true，则忽略center属性
    alignCenter: false,
    // 弹窗距离顶部的距离
    top: "1vh",
  }, options);
  return ElMessageBox.confirm(options.content, options.type, options);
};
//移动端确认框

const AlertMobile = (
  options = {
    title: "标题",
    message: "弹窗内容",
  }
) => {
  /*
   *@描述:移动端确认框options配置项-同vant 链接：https://vant-contrib.gitee.io/vant/v3/#/zh-CN/dialog
   *@作者: 肖华东
   *@日期: 2023-11-13 14:24:25
   *@title: string  标题
   *@width: number | string 弹窗宽度，默认单位为 px 320px
   *@message: string | () => JSX.ELement  文本内容，支持通过 \n 换行
   *@messageAlign: string 内容对齐方式，可选值为 left right center
   *@theme:string  样式风格，可选值为 round-button default
   *@className: string | Array | object 自定义类名
   *@showConfirmButton: boolean 是否展示确认按钮
   *@showCancelButton: boolean 是否展示取消按钮
   *@confirmButtonText:string 确认按钮文案
   *@confirmButtonColor:string 确认按钮颜色
   *@confirmButtonDisabled :boolean 是否禁用确认按钮
   *@cancelButtonText :string 取消按钮文案
   *@cancelButtonColor :string 取消按钮颜色
   *@cancelButtonDisabled  :boolean 是否禁用取消按钮
   *@overlay :boolean 是否展示遮罩层
   *@overlayClass:string | Array | object 自定义遮罩层类名
   *@overlayStyle:object 自定义遮自定义遮罩层样式罩层类名
   *@closeOnPopstate:boolean 是否在页面回退时自动关闭
   *@closeOnClickOverlay:boolean 是否在点击遮罩层后关闭弹窗
   *@lockScroll:boolean 是否锁定背景滚动
   *@allowHtml:boolean 是否允许 message 内容中渲染 HTML
   *@beforeClose:(action: string) => boolean | Promise<boolean> 关闭前的回调函数，返回 false 可阻止关闭，支持返回 Promise
   *@transition:string 动画类名，等价于 transition 的 name 属性
   *@teleport:string | Element 指定挂载的节点，等同于 Teleport 组件的
   */

  Dialog.alert(options).then(() => {
    // on close
  });
};
const ConfirmMobile = (
  options = {
    title: "标题",
    message: "弹窗内容",
  }
) => {
  /*
   *@描述:移动端确认框options配置项-同vant 链接：https://vant-contrib.gitee.io/vant/v3/#/zh-CN/dialog
   *@作者: 肖华东
   *@日期: 2023-11-13 14:24:25
   *@title: string  标题
   *@width: number | string 弹窗宽度，默认单位为 px 320px
   *@message: string | () => JSX.ELement  文本内容，支持通过 \n 换行
   *@messageAlign: string 内容对齐方式，可选值为 left right center
   *@theme:string  样式风格，可选值为 round-button default
   *@className: string | Array | object 自定义类名
   *@showConfirmButton: boolean 是否展示确认按钮
   *@showCancelButton: boolean 是否展示取消按钮
   *@confirmButtonText:string 确认按钮文案
   *@confirmButtonColor:string 确认按钮颜色
   *@confirmButtonDisabled :boolean 是否禁用确认按钮
   *@cancelButtonText :string 取消按钮文案
   *@cancelButtonColor :string 取消按钮颜色
   *@cancelButtonDisabled  :boolean 是否禁用取消按钮
   *@overlay :boolean 是否展示遮罩层
   *@overlayClass:string | Array | object 自定义遮罩层类名
   *@overlayStyle:object 自定义遮自定义遮罩层样式罩层类名
   *@closeOnPopstate:boolean 是否在页面回退时自动关闭
   *@closeOnClickOverlay:boolean 是否在点击遮罩层后关闭弹窗
   *@lockScroll:boolean 是否锁定背景滚动
   *@allowHtml:boolean 是否允许 message 内容中渲染 HTML
   *@beforeClose:(action: string) => boolean | Promise<boolean> 关闭前的回调函数，返回 false 可阻止关闭，支持返回 Promise
   *@transition:string 动画类名，等价于 transition 的 name 属性
   *@teleport:string | Element 指定挂载的节点，等同于 Teleport 组件的
   */
  return Dialog.confirm(options);
};
let rootNode = null;
let app = null;
const okToast = options => {
  const dom = document.body.querySelector(".dm-loading");
  if (!dom) {
    rootNode = document.createElement("div");
    // 给创建的元素设置 class 属性值
    rootNode.className = `dm-loading`;
    rootNode.style = "background-color: #99999941;position:fixed;z-index:99999;top:0;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content: space-around;";
    document.body.appendChild(rootNode);
  } else {
    app.unmount();
  }
  let vNode = h(Loading, { color: "#00000053", type: "spinner", size: "30px", textSize: "14px", textColor: "#c9c9c9", vertical: false });
  app = createApp(vNode);
  let domspan = document.createElement("span");
  domspan.innerText = "加载中...";
  dom.append(domspan);
  return app.mount(rootNode);
};
export default {
  message: IsPC() ? MessagePc : MessageMobile,
  alert: IsPC() ? AlertPc : AlertMobile,
  confirm: IsPC() ? ConfirmPc : ConfirmMobile,
};
