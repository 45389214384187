<script setup>
import { onMounted, onBeforeUnmount } from 'vue'
// import HelloWorld from "./components/HelloWorld.vue";
// import Footer from "./components/footer.vue";
// import Home from "./view/footer.vue";
import HelloWorld from './components/navHeader.vue'
import LeftSide from './components/leftSide.vue'
import Footer from './components/footer.vue'
import { useRoute, useRouter } from 'vue-router'
const $route = useRoute()
const $router = useRouter()
const onResize = () => {}
const handleCtrlKey = event => {
  // 列出你希望禁止的Ctrl+功能键
  // const forbiddenKeys = ['a', 'c', 'x', 'v', 'z']
  const forbiddenKeys = []

  // 检查是否按下了Ctrl键和列表中的一个键
  if (event.ctrlKey && forbiddenKeys.includes(event.key)) {
    // 阻止默认行为
    event.preventDefault()
    // 你可以在这里添加一个通知或日志，告知用户禁止的行为已被拦截
  }
}
onMounted(() => {
  if (!dm.getCookie('token')) {
    $router.replace('/home/index')
  }
  // Chrome IE 360
  window.addEventListener(
    'mousewheel',
    function (event) {
      if (event.ctrlKey === true || event.metaKey) {
        // dm.layer.message({
        //   message: "禁止操作",
        //   type: "warning",
        // });
        event.preventDefault()
      }
    },
    { passive: false }
  )

  //firefox
  window.addEventListener(
    'DOMMouseScroll',
    function (event) {
      if (event.ctrlKey === true || event.metaKey) {
        // dm.layer.message({
        //   message: "禁止操作",
        //   type: "warning",
        // });
        event.preventDefault()
      }
    },
    { passive: false }
  )
})
onBeforeUnmount(() => {
  // 销毁
  window.onresize = null
  // sessionStorage.removeItem("scrollTop");
})
</script>

<template>
  <div id="app-zry" @keydown.ctrl.exact="handleCtrlKey" v-resize="onResize">
    <!-- <router-view></router-view> -->

    <keep-alive :include="Component" v-if="$route.meta.keepAlive">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </keep-alive>
    <router-view v-slot="{ Component }" v-else>
      <component :is="Component" />
    </router-view>
  </div>
</template>

<style scoped>
#app-zry {
  position: relative;
  /* min-width: 1800px; */
  margin: 0 auto;
  /* overflow-x: hidden; */
}
</style>
