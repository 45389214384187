/*
 *@描述: 时间转换：几分钟，几小时，刚刚，几天，几月，几年
 *@作者: 肖华东
 *@日期: 2023-09-03 20:36:43
 *@updateTime: 时间：字符串
 */
function timeElapsed(updateTime) {
  if (updateTime === null) {
    return "";
  }
  let now = new Date().getTime();
  let second = Math.floor((now - updateTime) / 1000);
  let minute = Math.floor(second / 60);
  let hour = Math.floor(minute / 60);
  let day = Math.floor(hour / 24);
  let month = Math.floor(day / 31);
  let year = Math.floor(month / 12);
  if (year > 0) {
    return year + "年前";
  } else if (month > 0) {
    return month + "月前";
  } else if (day > 0) {
    let ret = day + "天前";
    if (day >= 7 && day < 14) {
      ret = "1周前";
    } else if (day >= 14 && day < 21) {
      ret = "2周前";
    } else if (day >= 21 && day < 28) {
      ret = "3周前";
    } else if (day >= 28 && day < 31) {
      ret = "4周前";
    }
    return ret;
  } else if (hour > 0) {
    return hour + "小时前";
  } else if (minute > 0) {
    return minute + "分钟前";
  } else if (second > 0) {
    return second + "秒前";
  } else {
    return "刚刚";
  }
}

/*
 *@描述:时间戳转换为时间
 *@作者: 肖华东
 *@日期: 2023-09-03 20:39:37
 *@timestamp: 时间戳
 */
function timeStampToTime(timestamp, number) {
  var date = new Date(timestamp);
  var Y = date.getFullYear() + "-";
  var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  var res = Y + M + D + h + m + s;
  if (number) {
    return res.substring(0, number);
  }
  return res;
}
/*
 *@描述:获取今天是星期几
 *@作者: 肖华东
 *@日期: 2023-09-03 20:41:00
 */
function getCurrentWeekday() {
  const myDate = new Date();
  let days = myDate.getDay();
  const number = days;
  switch (days) {
    case 1:
      days = "周一";
      break;
    case 2:
      days = "周二";
      break;
    case 3:
      days = "周三";
      break;
    case 4:
      days = "周四";
      break;
    case 5:
      days = "周五";
      break;
    case 6:
      days = "周六";
      break;
    case 0:
      days = "周日";
      break;
  }
  return {
    number: number,
    weekday: days,
  };
}

/*
 *@描述:获取当前是开始日期之后的第几周，如果大于total，则表示已经结束过时，如果小于0，则表示start还没有到来。
 *@作者: 肖华东
 *@日期: 2023-09-03 20:42:00
 *@start: 变量1
 *@total: 变量2
 */
function getCurrentWeekNumber(start, total) {
  const _arr = start.split("-");
  const y = _arr[0];
  const m = _arr[1];
  const d = _arr[2];
  const _start = new Date(y, parseInt(m) - 1, d);
  let _timestamp = _start.getTime();
  let day = _start.getDay(); // 星期几
  day = (day + 6) % 7; // 将星期几转化为距离星期一多少天
  // 我们将开始时间修正到那一周的星期一
  // 这里我们将星期天作为最后一天，星期一作为第一天
  _timestamp = _timestamp - day * (24 * 60 * 60 * 1000);
  // current
  const dt = new Date();
  const _y = dt.getFullYear();
  const _m = dt.getMonth();
  const _d = dt.getDate();
  const today = new Date(_y, _m, _d);
  const todayStamp = today.getTime();
  const diff = todayStamp - _timestamp;
  if (diff < 0) {
    // start还没有开始，未来返回-1
    return -1;
  }
  const weekStamp = 7 * 24 * 60 * 60 * 1000;
  let weekDiff = parseInt(diff / weekStamp);
  const more = diff % weekStamp;
  // if (more >= 24 * 60 * 60 * 1000) {
  // weekDiff += 1
  // }
  // wo always need to plus 1 for weekDiff
  const weekNumber = weekDiff + 1;
  if (weekNumber > total) {
    // 已经过期
    return -2;
  }
  return weekNumber;
}

function formatDate(value) {
  if (!value) return '';
  // 使用Date对象的实例方法格式化日期
  const date = new Date(value);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
export default {
  timeElapsed,
  timeStampToTime,
  getCurrentWeekday,
  getCurrentWeekNumber,
  formatDate
};