// import "typed.js";

/*
 *@描述:打字效果
 *@作者: 肖华东
 *@日期: 2024-04-07 21:36:37
 *@variable1: 变量1
 *@variable2: 变量2

*/
function typing(dom, text, speed = 300) {
  let element = document.querySelector(dom);
  if (!element) {
    return false;
  }
  let txt = JSON.parse(JSON.stringify(text));
  let txtArray = txt.split("");
  let newTxt = "";
  let index = 0;
  function _run(t1) {
    newTxt = newTxt + t1;
  }

  let timer = setInterval(() => {
    if (index == txtArray.length) {
      clearInterval(timer);
      timer = null;
      return false;
    }
    newTxt = newTxt + txtArray[index];
    element.innerText = newTxt;
    index++;
  }, speed);
  return newTxt;
}

window.typing = typing;
export default {
  typing,
};
