import { createApp } from "vue";
import "./style.css";
import "./assets/styles/index.scss";
//引入dm工具函数
const app = createApp(App);
import "@/utils/anime.js";
import App from "./App.vue";
//引入element-plus
import ElementPlus from "element-plus";
import anime from "animejs/lib/anime.js";
window.anime = anime;

import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import directives from "@/directives/index";
app.use(directives);
import "animate.css";
// 封面图比例
window.dip = 1.845771144278607
//将element-plus里面默认语言英文改为中文,需要引入它
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "./utils/index";
//引入dayjs日期插件
// import dayjs from "dayjs";
//引入国际化配置
// import lang from "./locale";
//引入router路由
import router from "./router";
//引入pinia状态管理
import pinia from "./pinia";
import "./assets/fonts/fonts.css";
import other from "./utils/other";
app.config.globalProperties.$format2num = other.format2num;
window.$format2num = other.format2num;
//使用element-plus,并设置语言为中文
app.use(ElementPlus, {
  locale: zhCn,
});
//通过provide全局注入工具函数
// app.provide("$dayjs", dayjs);
//国际化配置使用
// app.use(lang);
//使用router路由
app.use(router);
app.use(router);
app.use(anime);
//使用pinia状态管理
app.use(pinia);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
/*
 *@描述:获取components下导出的组件，全局注册
 *@作者: 肖华东
 *@日期: 2024-03-23 16:48:39
 */

import components from "@/components/index.js";
components.map(item => {
  app.component(item.__name, item);
});

app.mount("#app");
