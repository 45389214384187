// 浅拷贝
function shallowClone(obj) {
  if (Array.isArray(obj)) {
    return [...obj];
  } else if (typeof obj === "object" && obj !== null) {
    return { ...obj };
  } else {
    return obj;
  }
}

// 深拷贝
function deepClone(obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => deepCopy(item));
  } else if (typeof obj === "object" && obj !== null) {
    let copy = {};
    for (let key in obj) {
      copy[key] = deepCopy(obj[key]);
    }
    return copy;
  } else {
    return obj;
  }
}

export default {
  shallowClone,
  deepClone,
};
