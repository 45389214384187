function extend() {
  var i = 0
  var result = {}
  for (; i < arguments.length; i++) {
    var attributes = arguments[i]
    for (var key in attributes) {
      result[key] = attributes[key]
    }
  }
  return result
}
function decode(s) {
  return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent)
}
function api() { }
/**
 * 读取cookie，
 * 需要注意的是cookie是不能存中文的，如果需要存中文，解决方法是后端先进行编码encode()，
 * 前端取出来之后用decodeURI('string')解码。（安卓可以取中文cookie，IOS不行）
 * */
const getCookie = (key, json) => {
  if (typeof document === "undefined") {
    return
  }

  var jar = {}
  // To prevent the for loop in the first place assign an empty array
  // in case there are no cookies at all.
  var cookies = document.cookie ? document.cookie.split("; ") : []
  var i = 0

  for (; i < cookies.length; i++) {
    var parts = cookies[i].split("=")
    var cookie = parts.slice(1).join("=")

    if (!json && cookie.charAt(0) === '"') {
      cookie = cookie.slice(1, -1)
    }

    try {
      var name = decode(parts[0])
      cookie = decode(cookie)

      if (json) {
        try {
          cookie = JSON.parse(cookie)
        } catch (e) { }
      }

      jar[name] = cookie

      if (key === name) {
        break
      }
    } catch (e) { }
  }

  return key ? jar[key] : jar
}

/**
 * 设置cookie
 * key: cookie的名字，
 * value : cookie值，
 * expires: 过期时间（天数）
 * */
const setCookie = (name, value, options = {
  expires: 1, // 默认过期时间
}) => {
  // 默认过期时间
  const date = new Date();
  date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000)); // 设置默认过期时间
  // 设置cookie的过期时间
  const expires = "expires=" + date.toUTCString();

  // 设置cookie的路径
  const path = options.path ? "path=" + options.path : "path=/";

  // 设置cookie的域
  const domain = options.domain ? "domain=" + options.domain : "";

  // 设置cookie的安全属性
  const secure = options.secure ? "Secure; " : "";

  // 设置cookie的HttpOnly属性
  const httpOnly = options.httpOnly ? "HttpOnly; " : "";

  // 设置cookie的SameSite属性
  const sameSite = options.sameSite ? "SameSite=" + options.sameSite : "";

  // 创建cookie字符串
  const cookieString = name + "=" + encodeURIComponent(value) + ";" +
    expires + ";" +
    path + ";" +
    domain + ";" +
    secure +
    httpOnly +
    sameSite;
  // 设置cookie
  document.cookie = cookieString;
}


/**
 * 删除cookie
 * key: cookie的名字，
 * */
const delCookie = (key) => {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1)
  var cval = getCookie(key)
  if (cval != null)
    document.cookie = key + "=" + cval + ";expires=" + exp.toGMTString()
}
const removerCookies = (callback) => {
  // 方法一：使用 document.cookie 属性和过期时间来清除所有cookie

  // 获取当前所有的cookie
  var cookies = document.cookie.split(";")

  // 将过期时间设置为过去的时间，以清除cookie
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf("=")
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
  }
  if (callback && typeof callback === "function") {
    callback()
  }
}
export default {
  delCookie,
  setCookie,
  getCookie,
  removerCookies,
}
