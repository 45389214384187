import block from "markdown-it/lib/rules_core/block.mjs";
function toThousands(beforeNum) {

  return (beforeNum || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
}

 function format2num(beforeNum = 0, type=0, toFixed) {
  /* 转换为数值 */
  let afterNum = Number(beforeNum); // 12345 12345
  let fiexd = toFixed || 2;
  /* 转换单位 */
  if (type == 1) {
    afterNum = beforeNum / 10000;
  } else if (type == 2) {
    afterNum = beforeNum / 100000000;
  } // 12345 1.2345

  afterNum = afterNum.toFixed(fiexd);
  // 12345.00 1.23
  /* 添加逗号 */
  let arr = afterNum.split("."); // [12345] [1,23]
  arr[0] = toThousands(arr[0]); // ['12,345'] ['1',23]
  afterNum = arr.join(".");
  return afterNum;
}

/**
 * 保留n位小数，注意此方法返回的是 string类型，小数点后面不足的位数会自动补 0
 * @param {len} num 生成几位id
 * @returns string
 */
function loading(type = true, text = "加载中...", id) {
  if (!document.getElementById("dm-loading")) {

    var dom = document.createElement('div');
    var icon = document.createElement('div');
    icon.innerHTML = dm.loadingIconSvg

    dom.appendChild(icon);

    dom.setAttribute('id', "dm-loading")
    let domBody = document.body
    if (id) {
      domBody = document.getElementById(id);
    }
    if (text) {
      var p = document.createElement("text");
      text.split("").map(item => {
        var span = document.createElement("span");
        span.innerHTML = item
        p.appendChild(span)
      })

      p.setAttribute("data-content-before", text)
      p.setAttribute("class", "dm-loading-text loader typing-effect")

      dom.appendChild(p)
    }

    domBody.appendChild(dom);
    return false

  }
  if (type) {
    document.getElementById("dm-loading").style.display = 'flex'
  } else {
    document.getElementById("dm-loading").style.display = 'none'
  }
}
function loading2(type = true, text = "", id) {
  if (!document.getElementById("dm-loading2")) {

    var dom = document.createElement('div');
    var icon = document.createElement('div');
    dom.setAttribute('id', "dm-loading2")
    let domBody = document.body
    if (id) {
      domBody = document.getElementById(id);
    }
    if (text) {
      var p = document.createElement("text");
      text.split("").map(item => {
        var span = document.createElement("span");
        span.innerHTML = item
        p.appendChild(span)
      })

      p.setAttribute("data-content-before", text)
      p.setAttribute("class", "dm-loading-text loader typing-effect")

      dom.appendChild(p)
    }

    domBody.appendChild(dom);
    return false

  }
  if (type) {
    document.getElementById("dm-loading2").style.display = 'flex'
  } else {
    document.getElementById("dm-loading2").style.display = 'none'
  }
}

/**
 * 保留n位小数，注意此方法返回的是 string类型，小数点后面不足的位数会自动补 0
 * @len {len} num 生成几位id
 * @returns string
 */
function uuid(len) {
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  var uuid = [],
    i;
  var radix = radix || chars.length;
  var len = len || 16;
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "";
    uuid[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join("");
}


/**
 * 保留n位小数，注意此方法返回的是 string类型，小数点后面不足的位数会自动补 0
 * @param {number} num 初始数字
 * @param {number} n 保留几位小数
 * @returns string
 */


function tofixed(num, n) {
  const _num = Number(num)
  const _n = Number(n)
  const newNum = Math.round(_num * Math.pow(10, _n)) / Math.pow(10, _n)
  let str = newNum.toString()
  if (str.indexOf('.') < 0) {
    str += '.'
  }
  const len = str.length
  const idx = str.indexOf('.')
  for (let i = len - idx; i <= _n; i++) {
    str += '0'
  }
  return str
}

// 创建一个a标签，并做点击下载事件
function downloadFile(hrefUrl, fileName) {
  let a = document.createElement('a')
  a.href = hrefUrl
  a.download = fileName // 下载后文件名
  document.body.appendChild(a)
  a.click() // 点击下载
  document.body.removeChild(a) // 下载完成移除元素
}

// blobData 后台返回的文件流二进制数据
// mimeTypeStr 文件后缀名对应的type值(媒体文件类型)
// fileName 自定义文件名称
// 后端返回文件流下载（导出）文件
function downloadFileByFileFlow(blobData, mimeTypeStr, fileName) {
  let blob = new Blob([blobData], { type: mimeTypeStr })
  let hrefUrl = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadFile(hrefUrl, fileName);
}
// 示例：blobData为后端返回的文件流
// downloadFileByFileFlow(blobData, 'application/vnd.ms-excel', '订单明细');

// 封装blob对象
function dataURLToBlob(base64Str, mimeTypeStr) {
  let bstr = window.atob(base64Str); // 解码 base-64 编码的字符串，base-64 编码使用方法是 btoa()
  let length = bstr.length;
  let u8arr = new Uint8Array(length); // 创建初始化为0的，包含length个元素的无符号整型数组
  while (length--) {
    u8arr[length] = bstr.charCodeAt(length); // 返回在指定的位置的字符的 Unicode 编码
  }
  return new Blob([u8arr], { type: mimeTypeStr }); // 返回一个blob对象
}

// 后端返回base64公共导出
function downloadFileByBase64(base64Str, mimeTypeStr, fileName) {
  let blobObj = dataURLToBlob(base64Str, mimeTypeStr)
  let url = window.URL.createObjectURL(blobObj)
  downloadFile(url, fileName)
}
// 示例：res为后端返回的base64字符串
// downloadFileByBase64(res, 'application/vnd.ms-excel', '订单明细');

// 判断是否为空
function isEmpty(e) {
  switch (e) {
    case "":
    case null:
    case false:
    case undefined:
      return true;
    default:
      return false;
  }
}

// 数据类型判断以及判断是否为数字类型或者数字型字符串
function typeOf(obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

/*
 * JSON数组去重
 * @param: [array] json Array
 * @param: [string] 唯一的key名，根据此键名进行去重
 */
function uniqueArray(array, key) {
  var result = [array[0]]
  for (var i = 1; i < array.length; i++) {
    var item = array[i]
    var repeat = false
    for (var j = 0; j < result.length; j++) {
      if (item[key] == result[j][key]) {
        repeat = true
        break
      }
    }
    if (!repeat) {
      result.push(item)
    }
  }
  return result
}

/**
 * 两个数组去重
 * @param {Array} originList 原始数组
 * @param {Array} currentList 当前数组
 * @param {String} sortKey 去重字段
 * @return {Array} 返回新数组中不重复的部分
 */
function Duplicate(originList, currentList, sortKey = 'id') {
  const map = {}
  originList.forEach(e => {
    map[e[sortKey]] = true
  })
  return currentList.filter(e => !map[e[sortKey]])
}

// 数组包数组去重
function unique(arr) {
  arr = arr.filter(item => item.every(item => item)) // 去除[0,0]
  for (let i = 0, len = arr.length; i < len; i++) {
    for (let j = i + 1, len = arr.length; j < len; j++) {
      if (JSON.stringify(arr[i]) === JSON.stringify(arr[j])) {
        arr.splice(j, 1)
        j-- // 每删除一个数j的值就减1
        len-- // j值减小时len也要相应减1（减少循环次数，节省性能）
      }
    }
  }
  return arr
}

/**
 * 数组元素为对象的去重方法 ES5方法
 * @param arr  原数组
 * @param type  根据元素的某个属性进行去重
 * @returns {Array} 返回去重后的新的数组
 */
function Es5duplicate(arr, type) {
  var newArr = []
  var tArr = []
  if (arr.length == 0) {
    return arr
  } else {
    if (type) {
      for (var i = 0; i < arr.length; i++) {
        if (!tArr[arr[i][type]]) {
          newArr.push(arr[i])
          tArr[arr[i][type]] = true
        }
      }
      return newArr
    } else {
      for (var j = 0; j < arr.length; j++) {
        if (!tArr[arr[j]]) {
          newArr.push(arr[j])
          tArr[arr[j]] = true
        }
      }
      return newArr
    }
  }
}


/**
 * 数组元素为对象的去重方法 ES6方法
 * @param arr  原数组
 * @param type  根据元素的某个属性进行去重
 * @returns {Array} 返回去重后的新的数组
 */
function Es6duplicate(arr, type) {
  var hash = {}
  return arr.reduce(function (item, next) {
    hash[next[type]] ? '' : hash[next[type]] = true && item.push(next)
    return item
  }, [])
}

/**
 * 名称校验规则
 * @param {String} str 获取输入的名称
 * @return {Boolean} 返回是否校验通过  true为通过， false为不通过
 */
function nameRexp(str, required = true) {
  if (!required) {
    return {
      status: true
    }
  }
  if (!str) {
    return {
      message: '用户名不能为空',
      status: false
    }
  }
  str = str.trim()
  // 校验特殊字符
  // let special = /[^~!@#$%\^&*+|}{"：:<>?\/;''\[\]\\=`]$/;
  // let special = partyPersonNameNewReg;
  // if (!special.test(str)) {
  //   return {
  //     message: "录入结果应不包含特殊字符，请重新录入。",
  //     status: false
  //   };
  // }
  str = str.replace(/[\r\n]/g, '')
  if (getByteLen(str) < 3) {
    return {
      message: '请输入3个字节以上的长度',
      status: false
    }
  }
  // 判断是否含有数字,有数字  校验拦截
  const Num = /[0-9]/
  if (Num.test(str)) {
    return {
      message: '名称不允许含有数字',
      status: false
    }
  }
  // 校验是否为全中文
  const ZH = new RegExp('[\\u4E00-\\u9FFF]+$', 'g')
  if (ZH.test(str)) {
    // 当输入值为全中文时，校验是否有空格
    if (str.indexOf(' ') != -1) {
      return {
        message: '名称为全中文时中间不允许含有空格',
        status: false
      }
    } // 有空格
  }
  return {
    status: true
  }
}

/**
 * 身份证检验规则
 * @param {String} IDCard 获取输入的身份证号码
 * @return {Boolean} 返回是否校验通过  true为通过， false为不通过
 */
function idCard(IDCard) {
  // 身份证地区
  const areaID = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外'
  }
  // 41072119780706355X
  // var iSum = 0
  if (!/^\d{17}(\d|x)$/i.test(IDCard)) {
    return {
      status: false,
      message: '你输入的身份证长度或格式错误!'
    }
  }
  IDCard = IDCard.replace(/x$/i, 'a')
  if (areaID[parseInt(IDCard.substr(0, 2))] == null) {
    return {
      status: false,
      message: '你的身份证地区非法!'
    }
  }
  var sBirthday =
    IDCard.substr(6, 4) +
    '-' +
    Number(IDCard.substr(10, 2)) +
    '-' +
    Number(IDCard.substr(12, 2))
  var d = new Date(sBirthday.replace(/-/g, '/'))
  if (
    sBirthday !=
    d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
  ) {
    return {
      status: false,
      message: '身份证上的出生日期非法!'
    }
  }
  // for (var i = 17; i >= 0; i--)
  //   iSum += (Math.pow(2, i) % 11) * parseInt(IDCard.charAt(17 - i), 11)
  // if (iSum % 11 != 1)
  //   return {
  //     status: false,
  //     message: '你输入的身份证号非法!'
  //   }
  // aCity[parseInt(sId.substr(0,2))]+","+sBirthday+","+(sId.substr(16,1)%2?"男":"女");//此次还可以判断出输入的身份证号的人性别
  return {
    status: true,
    message: '校验成功！'
  }
}

/**
 * 根据身份证获取性别
 * @param {String} idCard 获取输入的身份证号码
 * @return {String} 返回性别 {男， 女}
 */
function IDCardSex(idCard) {
  var sexMap = {
    0: '女',
    1: '男'
  }
  if (idCard && idCard.length === 15) {
    return sexMap[idCard.substring(14, 15) % 2]
  } else if (idCard && idCard.length === 18) {
    return sexMap[idCard.substring(16, 17) % 2]
  } else {
    // 不是15或者18,null
    return 'error'
  }
}

/**
 * 校验比例输入  (输入为数字且在0-100之内)
 * @param {String Number} num 输入的比例值
 * @return {Boolean}  校验是否通过  true：通过 / false：未通过
 */
function propNum(num) {
  const regExp = /^(?:[1-9]?\d|100)$/
  if (regExp.test(num)) {
    return true
  } else {
    return false
  }
}

/**
 * 校验输入的年龄  （输入为数字且在0-120之内）
 * @param {String Number} num  输入的年龄
 * @return {Boolean}  校验是否通过  true：通过 / false：未通过
 */
function ageRexp(num) {
  const rexp = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/
  if (rexp.test(num)) {
    return true
  } else {
    return false
  }
}

/**
 * 联系电话校验
 * @param {String Number} num 录入的号码（手机号或者固定电话）
 * @return {Boolean} 校验是否通过  true：通过 / false：未通过
 */
function TelphoneNumber(num) {
  const str = num.toString()
  // 手机号校验正则
  const tel = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
  // 固定电话校验正则  （不含区号）
  const rexp = /\d{3}-\d{8}|\d{4}-\d{7}/
  if (!str.length) return false
  if (str.includes('-')) {
    const bool = rexp.test(str)
    return bool
  } else {
    const bool = tel.test(str)
    return bool
  }
}

// 下划线转换驼峰
function toHump(name) {
  return name.replace(/\_(\w)/g, function (all, letter) {
    return letter.toUpperCase()
  })
}
// 驼峰转换下划线
function toLine(name) {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase()
}

// 检查特殊字符
function specialCharactersCheck(str) {
  const pattern = new RegExp(
    "[`%~!@#$^&=|{}':'\\[\\].<>/?~！@#￥\\\\……&——|{}【】‘：”“'。、？]"
  )
  if (!str) return false
  const flag = Array.from(str).some(item => pattern.test(item))
  if (flag) return false
  return true
}
// 判断两个对象的值是否相等
function diffObject(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) {
    // //
    return false
  } else {
    for (const key in obj1) {
      if (!obj2.hasOwnProperty(key)) {
        return false
      }
      // 类型相同
      if (typeof obj1[key] === typeof obj2[key]) {
        // 同为引用类型
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          if (obj1[key] !== null && obj2[key] !== null) {
            const equal = diffObject(obj1[key], obj2[key])
            if (!equal) {
              // //
              return false
            }
          }
        }
        // 同为基础数据类型
        if (
          typeof obj1[key] !== 'object' &&
          typeof obj2[key] !== 'object' &&
          obj1[key] !== obj2[key]
        ) {
          // //
          return false
        }
      } else {
        if (
          (obj1[key] === null && obj2[key] === undefined) ||
          (obj2[key] === null && obj1[key] === undefined)
        ) {
          // 如果值为null和undefind则认为是相等的
          return true
        }
        // //
        return false
      }
    }
  }
  return true
}


export default {
  tofixed,
  downloadFile,
  downloadFileByFileFlow,
  dataURLToBlob,
  downloadFileByBase64,
  isEmpty,
  typeOf,
  uniqueArray,
  Es6duplicate,
  unique,
  Es6duplicate,
  nameRexp,
  Duplicate,
  idCard,
  IDCardSex,
  propNum,
  ageRexp,
  TelphoneNumber,
  specialCharactersCheck,
  toHump,
  toLine,
  specialCharactersCheck,
  diffObject,
  Es5duplicate,
  uuid,
  loading,
  loading2,
  format2num
}